import React from "react";
import "../assets/css/reviews.css";

const reviewData = [
  {
    Content:
      "Edulake exceeded my expectations in a way I had never even thought possible. I expected a single mentor from Scaler, but I ended up getting several people who guided me throughout. amet, consectetur adipiscing elit. Duis nec condimentum quam.",
    Author: "AB HADLEY",
    Brand: "Wallmart",
  },
  {
    Content:
      "Edulake exceeded my expectations in a way I had never even thought possible. I expected a single mentor from Scaler, but I ended up getting several people who guided me throughout. amet, consectetur adipiscing elit. Duis nec condimentum quam.",
    Author: "AB HADLEY",
    Brand: "Wallmart",
  },
  {
    Content:
      "Edulake exceeded my expectations in a way I had never even thought possible. I expected a single mentor from Scaler, but I ended up getting several people who guided me throughout. amet, consectetur adipiscing elit. Duis nec condimentum quam.",
    Author: "AB HADLEY",
    Brand: "Wallmart",
  }
];

const Reviews = () => {
  return (
    <section className="container py-8" id="reviewSection">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <h2>Our students are our biggest fans.</h2>
        </div>
      </div>
      <div class="row">
        {reviewData.map((review, index) => (
          <div class="col-12 col-md-6 col-lg-4 d-flex" key={index}>
            <div class="card mb-6 shadow-light-lg lift lift-lg">
              <div class="card-body pb-0">
                <p class="mb-2 review-desc">{review.Content}</p>
                <a href="#!" class="fw-bold text-decoration-none">
                  Read More... <i class="fe fe-arrow-right ms-3"></i>
                </a>
              </div>

              <div class="card-meta mt-auto" href="#!">
                <hr class="card-meta-divider" />

                <div class="avatar avatar-sm me-2">
                  <img
                    src={require("../assets/images/avatar-1.jpg")}
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>

                <h6 class="text-uppercase text-secondary me-2 mb-0">
                  {review.Author}
                </h6>

                <p class="h6 text-uppercase text-secondary mb-0 ms-auto">
                  <span>{review.Brand}</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Reviews;
