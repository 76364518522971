import React from "react";

export default function Process() {
  return (
    <section class="py-8 container" id="processSection">
      <div class="container">
        <div class="row align-items-center justify-content-between mb-8">
          <div class="col-12 col-md-6">
            <h6 class="text-uppercase text-primary fw-bold">Process</h6>

            <h2>
              Our process to help you learn is fast and you can do it from home.
            </h2>

            <p class="lead text-muted mb-6 mb-md-0">
              We keep everything as simple as possible by helping you start your
              learning journey in the best way.
            </p>
          </div>
          <div class="col-12 col-md-6 col-xl-5">
            <div class="row gx-0">
              <div class="col-4">
                <div
                  class="w-150 mt-9 p-1 bg-white shadow-lg aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img
                    src={require("../assets/images/photo-13.jpg")}
                    class="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
              <div class="col-4">
                <div
                  class="w-150 p-1 bg-white shadow-lg aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <img
                    src={require("../assets/images/photo-14.jpg")}
                    class="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
              <div class="col-4 position-relative">
                <div
                  class="w-150 mt-11 float-end p-1 bg-white shadow-lg aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <img
                    src={require("../assets/images/photo-14.jpg")}
                    class="img-fluid"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-auto col-md-12">
                <div class="row gx-0 align-items-center mb-md-5">
                  <div class="col-auto">
                    <a
                      href="#!"
                      class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>1</span>
                    </a>
                  </div>
                  <div class="col">
                    <hr class="d-none d-md-block me-n7" />
                  </div>
                </div>
              </div>
              <div class="col col-md-12 ms-n5 ms-md-0">
                <h3>Easy Registration</h3>

                <p class="text-muted mb-0 f-size-1">
                  In less than 2 minutes, make a new account or login using social media / Interviewbit
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-auto col-md-12">
                <div class="row gx-0 align-items-center mb-md-5">
                  <div class="col-auto">
                    <a
                      href="#!"
                      class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>2</span>
                    </a>
                  </div>
                  <div class="col">
                    <hr class="d-none d-md-block me-n7" />
                  </div>
                </div>
              </div>
              <div class="col col-md-12 ms-n5 ms-md-0">
                <h3>Quick Evaluation</h3>

                <p class="text-muted mb-0 f-size-1">
                  Simple 30 minutes MCQ test, focused on aptitude and basic coding to find the right course for you.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-auto col-md-12">
                <div class="row gx-0 align-items-center mb-md-5">
                  <div class="col-auto">
                    <a
                      href="#!"
                      class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>3</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col col-md-12 ms-n5 ms-md-0">
                <h3>Enroll in your course</h3>

                <p class="text-muted mb-0 f-size-1">
                  Sign up with our various EMI options to swiftly kickstart your learning journey.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-auto col-md-12">
                <div class="row gx-0 align-items-center mb-md-5">
                  <div class="col-auto">
                    <a
                      href="#!"
                      class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>4</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col col-md-12 ms-n5 ms-md-0">
                <h3>Upgrade your batch!</h3>

                <p class="text-muted mb-0 f-size-1">
                  Want a shorter course? Take the coding challenge after enrollment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
