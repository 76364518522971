import React from 'react';
import '../assets/css/banner.css'
import Login from "./Login";

const Banner =()=>{
    return(
        <section className="container content-bg py-8 section-top" id='bannerSection'>
            <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-6 order-md-2 aos-init aos-animate">
                <Login/>
                
            </div>
            <div className="col-12 col-md-6 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-up">
            <div className="d-flex">
                <div className="px-3">
                    <h5 className="mb-0">
                    <span data-countup="{&quot;startVal&quot;: 0}" data-to="100" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">126</span>%
                    </h5>
                    <small className="text-gray-700 mb-0">
                    Avg. CTC Hike
                    </small>
                </div>
                <div className="border-start border-gray-300"></div>
                <div className="px-3">
                    <h5 className="mb-0">
                    <span data-countup="{&quot;startVal&quot;: 0}" data-to="24" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">Top 1</span>%
                    </h5>
                    <small className="text-gray-700 mb-0">
                    Industry Instructor
                    </small>
                </div>
                <div className="border-start border-gray-300"></div>
                <div className="px-3">
                    <h5 className="mb-0">
                    <span data-countup="{&quot;startVal&quot;: 0}" data-to="100" data-aos="" data-aos-id="countup:in" className="aos-init aos-animate">900</span>+
                    </h5>
                    <small className="text-gray-700 mb-0">
                    Placement Partners
                    </small>
                </div>
                </div>
                <h1 className="text-center text-md-start mb-4 mt-4">
                Your Path to a <span className="text-primary">Successful Tech Career</span>!
                </h1>

                <p className="lead text-center text-md-start text-muted mb-6">
                Now experience the program for <strong>FREE</strong> 
                </p>

                <div className="text-center text-md-start">
                <a href="overview.html" className="btn btn-primary shadow lift me-2">
                    BOOK A LIVE CLASS <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                </a>
                <a href="docs/index.html" className="btn btn-primary-soft lift">
                    EXPLORE ACADEMY
                    {/* <p>Comment</p> */}
                </a>
                </div>

            </div>
            </div> 
        </section>
    );
    }

export default Banner;