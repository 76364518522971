import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../assets/css/curriculum.css";

function Curriculum() {
  return (
    <section class="container py-8" id="curriculumSection">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <h2>Curriculum is designed to make you a solid engineer</h2>
        </div>
      </div>
      <div class="col-md-12">
        <Tabs
          defaultActiveKey="beginner"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="beginner" title="Beginner">
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
          <Row>
            <Col sm={5}>
              <div class="card card-border shadow-light-lg aos-init aos-animate">
                <div class="card-body p-0">
                  <ListGroup className="curriculum-text">
                    <ListGroup.Item action href="#link1">
                      Programming Language Fundamentals
                      <div class="d-flex w-100 justify-content-between mt-1">
                        <small>Module-1</small>
                        <small>2 Months</small>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="#link2">
                      Data Structures and Algorithms
                      <div class="d-flex w-100 justify-content-between mt-1">
                        <small>Module-2</small>
                        <small>4.5 Months</small>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="#link3">
                      SQL
                      <div class="d-flex w-100 justify-content-between mt-1">
                        <small>Module-3</small>
                        <small>0.5 Months</small>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item action href="#link4">
                      LLD and Project Specialisations
                      <div class="d-flex w-100 justify-content-between mt-1">
                        <small>Module-4</small>
                        <small>3.5 Months</small>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Col>
            <Col sm={7}>
              <Tab.Content>
                <Tab.Pane eventKey="#link1">
                  <div class="card card-border border-success shadow-light-lg aos-init aos-animate">
                    {/* <div class="card-header">2 Months</div> */}
                    <div class="card-body">
                      <ul class="curriculum-content__list">
                        <li>
                          <p>Programming Language Fundamentals</p>
                        </li>
                        <ul class="curriculum-content__list">
                          <li>
                            <p>Introduction to Java</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Input Output and Data Types</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Operators</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Conditions</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Loops</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Pattern Problems</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Functions</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>1D and 2D Arrays</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Strings</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Memory Management</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Basic OOP for Problem Solving</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="#link2">
                  <div class="card card-border border-success shadow-light-lg aos-init aos-animate">
                    {/* <div class="card-header">2 Months</div> */}
                    <div class="card-body">
                      <ul class="curriculum-content__list">
                        <li>
                          <p>Data Structures and Algorithms</p>
                        </li>
                        <ul class="curriculum-content__list">
                          <li>
                            <p>Time and Space Complexity</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Array Problem Solving Techniques</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Bit Manipulation</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Maths for Problem Solving</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Recursion</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Backtracking</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Sorting</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Searching(Binary Search)</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Two Pointers</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Hashing</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                          <li>
                            <p>Strings and Pattern Matching</p>
                          </li>
                          <ul class="curriculum-content__list"></ul>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
          </Tab>
          <Tab eventKey="intermediate" title="Intermediate">
            Tab content for Intermediate
          </Tab>
          <Tab eventKey="advanced" title="Advanced">
            Tab content for Advanced Tab
          </Tab>
          
        </Tabs>
        
      </div>
    </section>
  );
}

export default Curriculum;
