import React from "react";
import "../assets/css/services.css";

const serviceData = [
  {
    id: 0,
    Keyword: "Dedication",
    Desc: "Dedicated recruiter team & 100+ employer partners help bring you diverse options.",
  },
  {
    id: 1,
    Keyword: "Exchange",
    Desc: "Exchange job opportunities with our extensive Scaler student community.",
  },
  {
    id: 1,
    Keyword: "Practice",
    Desc: "Practice mock interviews with people working in the industry.",
  },
  {
    id: 3,
    Keyword: "Optimization",
    Desc: "Optimize your resume & LinkedIn profile with our experienced experts.",
  },
];

export default function Services() {
  return (
    <section className="container-fluid" id="serviceSection">
      <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x text-dark">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div class="bg-dark py-8">
        <div class="container my-4">
          <div class="row mx-5 align-items-center">
            <div class="col-12 col-md-5 order-md-2 text-right">
              <img
                src={require("../assets/images/illustration-7.png")}
                alt="..."
                class="img-fluid mb-6 mb-md-0 mw-60"
              />
            </div>
            <div class="col-12 col-md-7 order-md-1 text-white">
              <h2 class="mb-0">Become a Scaler Mentor </h2>
              <p>
                A chance to give back to the community. Mentor Scaler students
                and help them unlock their true potential.
              </p>
              <a href="bnt.html" class="btn btn-white btn-xs shadow lift">
                Know More
              </a>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-8">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <h2 class="mb-0 text-white">Career Services</h2>
            <p class="fs-lg text-muted mb-7 mb-md-9 px-md-9">
              Along with preparing you for the job, we also help you prepare for
              your search & your interviews.
            </p>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            {serviceData.map((service, index) => (
              <div class="col-md-6" key={index}>
                <div class="d-flex">
                  <div class="badge badge-lg badge-rounded-circle bg-success">
                    <span
                      class="fe fe-check"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      aria-label="check"
                      data-bs-original-title="check"
                    ></span>
                  </div>

                  <div class="ms-5">
                    <h4 class="text-white">{service.Keyword}</h4>

                    <p class="text-muted mb-6 mb-md-8">{service.Desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
