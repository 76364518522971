import React from "react";

export default function Footer() {
  return (
    <section class="footer-content bg-dark" id="footerSection">
      <footer class="container py-8">
        <div class="row">
          <div class="col-md-4">
            <a href="#home" class="brand-footer-logo text-white">
              EDULAKE
            </a>
            <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0 mt-4">
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  About Us
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Become a Mentor
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Become a TA
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Become a Career Coach
                </a>
              </li>
              <li>
                <a href="#!" class="text-reset">
                  Blogs
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="fw-bold text-uppercase text-gray-700">Tutorials</h6>

            <ul class="list-unstyled text-muted mb-0">
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Data Structures
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Python
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Java
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  DBMS
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  C & C++
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  SQL
                </a>
              </li>
              <li>
                <a href="#!" class="text-reset">
                  Data Science
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-4 ">
            <h6 class="fw-bold text-uppercase text-gray-700">Contact Us</h6>

            <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Email - edulake@yopmail.com
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Phone - xxxxxxxxxxx
                </a>
              </li>
              <li class="mb-3">
                <a href="#!" class="text-reset">
                  Website - edulake.co.uk
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div class="container py-4">
        <div class="row copyright-text text-center">
          <div class="text-muted order-2 order-md-1">
            <span class="fw-semibold me-1">2023 ©</span>
            <a href="https://edulake.co.uk/" class="text-success    ">
              Edulake
            </a>{" "}
            All Rights Reserved
          </div>
        </div>
      </div>
    </section>

  );
}
