import React from "react";
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Home from "./Home";
import Faq from "./Faq";

export default function Main() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/faq" element={<Faq/>} />
    </Routes>
    </BrowserRouter>
  );
}
