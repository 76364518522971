import React from "react";

import structure from '../assets/images/img1.png';
import faculty from '../assets/images/img2.png';
import experience from '../assets/images/img3.png';
import mentorship from '../assets/images/img4.png';
import support from '../assets/images/img5.png';
import peer from '../assets/images/img6.png';
import "../assets/css/features.css";

const cardData = [
  {
    icon:structure,
    Title: "Structured, industry vetted curriculum",
  },
  {
    icon:faculty,
    Title: "Live classes by faculty who have been there, done that",
  },
  {
    icon:experience,
    Title: "Practical experience through real-life projects",
  },
  {
    icon:mentorship,
    Title: "Regular 1:1 mentorship from industry veterans",
  },
  {
    icon:support,
    Title:
      "Career support via a dedicated recruitment team, alumni network, etc.",
  },
  {
    icon:peer,
    Title: "Aspirational peer group of 2,000+ Scaler students & alumni",
  },
];

const Features = () => {
  return (
    <section className="container py-8" id="featureSection">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <h2>
            A program for engineers to master Problem Solving & System Design
          </h2>
        </div>
      </div>

      <div className="row mt-n7 service-bg">
        {cardData.map((card, index) => (
          <div key={index} className="col-12 col-sm-6 col-lg-4 mt-7">
            <div className="card h-100 bg-dark rounded-3 lift lift-lg lift lift-lg">
              <div className="card-body p-5 pb-0 ">
                <div className="avatar avatar-xl">
                    <img src={card.icon} alt="Favicon" className="avatar-img" />
                </div>
              </div>
              <div className="card-body p-5">
                <h3 className="fw-bold text-primary">{card.Title}</h3>
              </div>
            </div>

            {}
          </div>
        ))}
      </div>
      {/* <div className="Heading">
                <h1>A program for engineers to master Problem Solving & System Design</h1>
            </div> */}
      {/* {cardData.map((card,index) => (
          <div key={index} className={`card ${card.color}`}>
            <img src={card.favicon} alt="Favicon" className="favicon" />
            {card.content}
            {}
          </div>
                ))} */}
    </section>
  );
};

export default Features;
