import React from "react";

import Banner from "./Banner";
import Program from "./Program";
import Features from "./Features";
import Curriculum from "./Curriculum";
import Reviews from "./Reviews";
import Services from "./Services";
import Brands from "./Brands";
import Process from "./Process";
import Benefits from "./Benefits";
import Courses from "./Courses";

export default function Home() {
  return (
    <section>
      <Banner />
      <Features />
      <Program />
      <Curriculum />
      <Reviews />
      <Services />
      <Brands />
      <Process />
      <Benefits />
      <Courses />
    </section>
  );
}
