import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";

import "../assets/css/navbar.css";
import Login from "./Login";

const menuItems = [
  { id: 1, label: "Home", link: "/" },
  { id: 2, label: "FAQs", link: "/faq" },
  // { id: 3, label: 'Reviews', link: '#reviewSection' },
  // { id: 4, label: 'Teaching', link: '#teachingSection' },
  // { id: 5, label: 'Mentorship', link: '#mentorshipSection' },
  // { id: 6, label: 'Community', link: '#communitySection' },
  // { id: 7, label: 'Careers', link: '#careersSections' },
];

function LigthNavbar() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Navbar bg="white" data-bs-theme="white" fixed="top">
      <Container>
        <Navbar.Brand href="/" className="brand-logo">
          EDULAKE
        </Navbar.Brand>
        <Nav className="ms-auto">
          {menuItems.map((item) => (
            <Nav.Link key={item.id} href={item.link}>
              {/* <Link to={item.link}>{item.label}</Link> */}
              {item.label}
            </Nav.Link>
          ))}
        </Nav>
        <Button
          variant="primary"
          className="navbar-btn btn btn-sm btn-primary lift ms-auto"
          onClick={() => setModalShow(true)}
        >
          Login
        </Button>

        <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
    </Navbar>
  );
}

function LoginModal(props) {
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header >
         <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      {/* <Modal.Body> */}
      <CloseButton onClick={props.onHide} />
      <Login />
      {/* </Modal.Body> */}
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default LigthNavbar;
