import React from "react";
import "../assets/css/program.css";

const benefits = [
  {
    title: "Missed a class?",
    description:"Watch the recording later, with teaching assistants available to solve your doubts",
  },
  {
    title: "Work / family needs time?",
    description:"Pause your course and restart a month later with the next batch!",
  },
  {
    title: "Jobs & class timings clash?",
    description: "Decide your ideal class timing together with your classmates",
  },
  {
    title: "Want to revise?",
    description:"Access assignments/notes lifelong and recordings upto 6 months post course completion",
  },
  {
    title: "Have doubts?",
    description:"Get them resolved over text / video by our expert teaching assistants!",
  },
];


export default function Program() {
  return (
    <section className="container py-8" id="programSection">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-md-5 order-md-2">
          <span class="badge bg-success-soft rounded-pill mb-3">
            <span class="h6 text-uppercase">Benefits</span>
          </span>

          <h2>
            We have designed <br />
            <span class="text-success">a flexible program for you</span>.
          </h2>

          <p class="fs-lg text-muted mb-6 mb-md-0">
            Few instances where you will find us helping you navigate through
            the best of the courses in the most flexible way.
          </p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="card card-border border-success shadow-lg">
            <div class="card-body">
              <div class="list-group list-group-flush benefit-content">
                {benefits.map((benefit, index) => (
                  <div
                    class="list-group-item d-flex align-items-center"
                    key={index}
                  >
                    <div class="me-auto">
                      <p class="fw-bold mb-1">{benefit.title}</p>

                      <p class="fs-sm text-muted mb-0">
                        {benefit.description}.
                      </p>
                    </div>

                    <div class="badge badge-rounded-circle bg-success-soft ms-4">
                      <i class="fe fe-check"></i>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
