import React from "react";

export default function Brands() {
  return (
    <section class="py-8 bg-white shadow-light-lg lift lift-lg" id="brandSection">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <h2>Companies where our alumni work.</h2>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-center border border-gray-300">
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/google.png")}
                alt="Google"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/amazon.png")}
                alt="Amazon"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/microsoft.png")}
                alt="Microsoft"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/adobe.png")}
                alt="Adobe"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/myntra.png")}
                alt="Myntra"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/flipkart.png")}
                alt="Flipkart"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img
                src={require("../assets/images/brands/goldmansachs.png")}
                alt="GoldmanSachs"
              />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img src={require("../assets/images/brands/ola.png")} alt="Ola" />
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md-2 mb-4">
            <div class="img-fluid text-white mb-2 mb-md-0">
              <img src={require("../assets/images/brands/olx.png")} alt="Olx" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
